/**
 * Spacer
 */

import React from 'react';
import { graphql } from 'gatsby'

const Spacer = ({ size }) => (
  <div className={`spacer--${size}`} />
);

export default Spacer;

export const pageBuilderSpacerQuery = graphql`
  fragment PageBuilderSpacer on WordPressAcf_spacer {
    ... on WordPressAcf_spacer {
      size
    }
  }
`